import React, { Component, useEffect, useState } from 'react';
import { Switch, Route, useParams } from "react-router-dom";

import "./style/App.css";

import GoogleMap from "./components/GoogleMap";
import Layout from "./components/Layout";

function App() {
  return (
    <div id="App">
      <Layout />
    </div>
  );
}

export default App;
