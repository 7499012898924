import { useEffect, useRef, useState, useReducer } from "react";
import Webcam from "webcam-easy";

import { MDBBtn, MDBIcon } from 'mdbreact';

export const Camera = ({ cameraToggle, addMarkerToMap }) => {
  const [picture, setPicture] = useState(null);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const webcamElement = useRef(null);
  const canvasElement = useRef(null);
  const snapSoundElement = useRef(null);

  console.log("webcamelement ", webcamElement.current);

  const webCamRef = useRef(null);


  useEffect(() => {

    webCamRef.current = new Webcam(
      webcamElement.current,
      "environment",
      canvasElement.current,
      snapSoundElement.current
    );

    webCamRef.current
      .start()
      .then((result) => {
        webCamRef.current.className = "webcam-wrapper";
        console.log("webcamred", webCamRef.current);
        console.log("webcam started");
      })
      .catch((err) => {
        console.log(err);
      });

    const app = document.getElementById("App");
    app.style.backgroundColor = "black";
  }, []);

  return (
    <>
      {!picture && (
        <>
          <video
            id="webcam"
            ref={webcamElement}
            autoplay
            playsinline
            className="take-video-wrapper"
          ></video>
          <canvas id="canvas" ref={canvasElement} class="d-none"></canvas>
          <audio
            id="snapSound"
            ref={snapSoundElement}
            src="audio/snap.wav"
            preload="auto"
          ></audio>
        </>
      )}

      {console.log("picture========", picture)}

      {!picture && (

        <MDBBtn
              style={{
                  position: "fixed",
                  bottom: "5vh",
                  zIndex: "11",
              }}
              color="success" onClick={() => {
              // webCamRef.current.flip({ facingMode: "enviroment" });
              let picture = webCamRef.current.snap();
              // document.querySelector("#download-photo").href = picture;

              document.querySelector("#download-photo").src = picture;
              const img = document.getElementById("download-photo");
              img.style.display = "block";
              webCamRef.current.stop();

              setPicture(picture);
          }}>
           <MDBIcon icon="camera" className="mr-2" />
           გადაღება
        </MDBBtn>
      )}

      <img id="download-photo" />
      {picture && (
        <div
          style={{
            width: "100%",
            height: "60px",
            position: "fixed",
            bottom: 0,
            display: "flex",
            justifyContent: "space-between",
          }}
        >

          <MDBBtn color="danger" onClick={() => cameraToggle()} >
             <MDBIcon icon="window-close" className="mr-2" />
             გაუქმება
          </MDBBtn>


          <MDBBtn color="success" onClick={() => addMarkerToMap(picture)} >
             <MDBIcon icon="arrow-circle-up" className="mr-2" />
             ატვირთვა
          </MDBBtn>
        </div>
      )}
    </>
  );
};
