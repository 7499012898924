import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import siteLogo from "../assets/logo-6.png";

import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBFormInline,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBContainer, MDBRow, MDBCol,
    MDBCard, MDBCardTitle, MDBBtn, MDBCardGroup, MDBCardImage, MDBCardText, MDBCardBody
} from "mdbreact";

import { Switch, Route } from 'react-router-dom';

import GoogleMap from "./GoogleMap";
import List from "./List";


class Layout extends Component {

    state = {
      isOpen: false
    };

    toggleCollapse = () => {
      this.setState({ isOpen: !this.state.isOpen });
    }



    render(){
        return (<>
            <MDBNavbar color="orange" dark expand="md">

              <MDBNavbarBrand href="/">
                <img src={siteLogo} height="30" alt="Adapted Map" className="pl-2 mr-1" />
                <strong className="black-text">Adapted Map</strong>
              </MDBNavbarBrand>

              <MDBNavbarToggler onClick={this.toggleCollapse} />

              <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar className="pr-2">
                <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBNavLink className='black-text' to="/manual">ინსტრუქცია</MDBNavLink>
                </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink className='black-text' to="/about">პროექტის შესახებ</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink className='black-text' to="/list">ობიექტების სია</MDBNavLink>
                  </MDBNavItem>


                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>



                <Switch>
                    <Route path='/about'>
                        <MDBContainer className="pt-4">
                            <h1>პროექტის შესახებ</h1>
                            <p className="text-left">ადაპტირებული რუკა შეზღუდული შესაძლებლობების მქონე ადამიანებს საშუალებას აძლევს, ნახონ რამდენად ადაპტირებულია სხვადასხვა ობიექტი საქართველოში და ვიზიტი შესაბამისად დაგეგმონ.</p>

                            <p className="text-left">ადაპტირებული რუკა "თანასწორობის ჰაკათონზე" შექმნილი პლატფორმაა, რომლის მიზანიც ინკლუზიური გარემოს შექმნაა.
                            რუკა ,,ქრაუდსორსინგით" ფუნქციონირებს, რაც ნიშნავს, რომ მის დახვეწასა და განვითარებაში მოაქალაქეების ჩართულობა გადამწყვეტ როლს ასრულებს. პროექტში წვლილის შესატანად საჭიროა, რუკაზე მონიშნოთ თქვენთვის სასურველი ობიექტი, უპასუხოთ რამდენიმე კითხვას, რაც დაგვეხმარება, დავადგინოთ რამდენად ადაპტირებულია სივრცე, და დაურთოთ ფოტო-სურათი მტკიცებულების სახით.</p>

                            <p className="text-left">ჩვენ ყველას ერთად შეგვიძლია გარემო გავხადოთ იმაზე უკეთესი, ვიდრე ის გუშინ იყო.</p>
                            
                            <p className="text-left">ეს პროდუქტი შეიქმნა თანასწორობის ჰაკათონის ფარგლებში, რომელიც კამპანიის  „მე ვირჩევ თანასწორობას“ მხარდაჭერით ჩატარდა.</p>

                            <p className="text-left">კამპანია მიმდინარეობს ევროპის საბჭოს თანამშრომლობის პროექტის „დისკრიმინაციის, სიძულვილით მოტივირებული დანაშაულისა და სიძულვილის ენის წინააღმდეგ ბრძოლა საქართველოში“ ფარგლებში. პროექტი ხორციელდება საქართველოში ევროპის საბჭოს 2020-2023 წლების სამოქმედო გეგმის მიხედვით და დანიის სამეზობლო პროგრამის (DANEP) მხარდაჭერით.</p>

                            <p className="text-left">პროექტის პარტნიორები არიან <a href="https://www.coe.int/ka/web/tbilisi/the-coe/" target="_blank">ევროპის საბჭო</a>, <a href="https://macgeorgia.org/" target="_blank">MAC Georgia</a> და  <a href="http://forset.ge/" target="_blank">ფორსეტი</a>.</p>
                        </MDBContainer>
                    </Route>
                    <Route path='/manual'>
                        <MDBContainer className="pt-4">
                            <h1>რუკაზე ობიექტის დამატების ინსტრუქცია</h1>
                            <p className="text-left">
                                <br /><br />იმისთვის, რომ რუკაზე ადაპტირებული სივრცე დაამატოთ, ოთხ მარტივ საფეხურს უნდა მიჰყვეთ: <br /><br />
                                1. დააკლიკეთ რუკაზე გამოტანილ მწვანე ღილაკს;<br />
                                2. მონიშნეთ თქვენთვის სასურველი ობიექტი;<br />
                                3. უპასუხეთ ეკრანზე გამოტანილ რამდენიმე კითხვას, რაც დაგვეხმარება, დავადგინოთ, რამდენად ადაპტირებულია სივრცე;<br />
                                4. და ბოლოს, დაურთეთ ფოტო-სურათი მტკიცებულებების სახით.<br /><br />
                                მადლობა თანამშრომლობისთვის!
                            </p>

                        </MDBContainer>
                    </Route>
                    <Route path='/list'>
                        <List />
                    </Route>
                    <Route>
                        <GoogleMap />
                    </Route>
                </Switch>

            </>);
    }

}



export default Layout;
