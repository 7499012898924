import React, { Component, useEffect, useState, useRef, useMemo } from "react";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import MainIcon1 from "../assets/chair-pin60.png";
import MainIcon2 from "../assets/chair-pin60-2.png";
import MainIcon3 from "../assets/chair-pin60-3.png";
import MainIcon4 from "../assets/chair-pin60-4.png";
import MainIcon5 from "../assets/chair-pin60-5.png";
import MainIcon6 from "../assets/chair-pin60-6.png";
import MainIconNew from "../assets/chair-pin60orange.png";
import axios from "axios";
import FormData, { from } from "form-data";
import { Camera } from "./Camera";

import {
    MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,
    MDBCard, MDBCardTitle, MDBCardImage, MDBCardText, MDBCardBody,
    MDBIcon, MDBInput, MDBRow, MDBCol,
    MDBNotification
} from 'mdbreact';

//const apiUrl = "http://127.0.0.1:8000";
const apiUrl = "";
//const apiUrl = "./p.php?url=http://adapted.vizhack.ge";
//const apiUrl = "https://buyers.ge/jobs/cmrkw/build/p2.php?full_headers=1&full_status=1&url=http://adapted.vizhack.ge";
//const apiUrl = "http://adapted.vizhack.ge";

const apiKey = "AIzaSyBmHRdO5ZYI8DzGpmjDh4FikJUuf-EWG4Q";

const MapCountainer = (props) => {
  const [currLocation, setCurrLocation] = useState(null);
  const [markers, setMarkers] = useState(null);

  const [currLocationAddress, setCurrLocationAddress] = useState("");

  const [placedMarker, setPlacedMarker] = useState(null);

  useEffect(async () => {
    console.log("------------------->");
    const response = await axios.get(`${apiUrl}/api/map/markers/`);
    setAllMarkers(response.data);
    console.log("reponse", response);

    let stream = null;
    const constraints = {
      video: true
    };

    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      /* use the stream */
    } catch (err) {
      /* handle the error */
    }


  }, []);
  const [allMarkers, setAllMarkers] = useState([]);
  const [placingMarker, setPlacingMarker] = useState(false);
  const [state, setState] = useState({
    // for google map places autocomplete
    address: "",

    modal: false,
    modalNew: false,

    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},

    justAddedPin: {},

    takingPhoto: false,

    mapCenter: {
      lat: 41.6944227,
      lng: 44.8008687,
    },
  });

  const modaltoggle = async () => {
    setState({ ...state, modal: !state.modal });
  };

  const modalNewtoggle = async () => {
    setState({ ...state, modalNew: !state.modalNew });
  };


  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setState({
          ...state,
          mapCenter: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
        setCurrLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        console.log("++++++Position 1:");
        console.log(position.coords);
      });
    } else {
      window.alert("location not avaliable");
      console.log("Not Available");
    }
    function handlePermission() {
      navigator?.permissions
        ?.query({ name: "geolocation" })
        .then(function (result) {
          if (result.state == "granted") {
            report(result.state);
            // geoBtn.style.display = "none";
          } else if (result.state == "prompt") {
            report(result.state);
            // geoBtn.style.display = "none";
            // navigator.geolocation.getCurrentPosition(
            //   revealPosition,
            //   positionDenied,
            //   geoSettings
            // );
          } else if (result.state == "denied") {
            report(result.state);
            // geoBtn.style.display = "inline";
          }
          result.onchange = function () {
            report(result.state);
          };
        });
    }

    function report(state) {
      console.log("Permission p[k[" + state);
    }

    handlePermission();
  }, []);

  const mapRef = useRef("");

  const takePhoto = (cb) => {
    const picture = cb();
    console.log("picture taken", picture);
    setState({ ...state, takingPhoto: false });
  };

  useEffect(() => {
    setState({
      ...state,
      mapCenter: {
        lat: props?.currLocation?.lat,
        lng: props?.currLocation?.lng,
      },
    });
  }, []);

  const onMarkerClick = (props, marker, e) => {
    if ( marker?.name == "added marker" ) {
        setState({
          ...state,
          selectedPlace: props,
          activeMarker: marker,
          modalNew: true,
        });
    } else {
        setState({
          ...state,
          selectedPlace: props,
          activeMarker: marker,
          modal: true,
        });
    }

    getLocationByLatLng({ lat: marker.lat, lng: marker.lng });
  };
  const onMapClicked = (props) => {
    if (state.showingInfoWindow) {
      setState({ ...state, showingInfoWindow: false, activeMarker: null });
    }
  };



  const addMarkerToMap = async (picture) => {
    try {
      const formData = new FormData();
      formData.append(
        "name",
        await getLocationByLatLng({
          lat: placedMarker.lat,
          lng: placedMarker.lng,
        })
      );
      formData.append("lat", placedMarker.lat);
      formData.append("lng", placedMarker.lng);

      formData.append("name", placedMarker.title);
      formData.append("address", placedMarker.address);
      formData.append("description", placedMarker.description);

      formData.append("obj_type", placedMarker.obj_type);

      formData.append("opt_pandus", placedMarker.opt_pandus);
      formData.append("opt_toilet", placedMarker.opt_toilet);
      formData.append("opt_elevator", placedMarker.opt_elevator);
      formData.append("opt_handling", placedMarker.opt_handling);

      const response = await axios.post(`${apiUrl}/api/map/markers/`, formData);
      const formData2 = new FormData();
      formData2.append("image", picture);
      formData2.append("marker", response.data.id);

      const response2 = await axios.post(
        `${apiUrl}/api/map/markerImages/`,
        formData2,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      setState({ ...state, takingPhoto: false, modalNew: false });
      setPlacingMarker(null);
    } catch (e) {
      console.log("error---->", Object.keys(e));
      console.log(e.toJSON());
      console.log(e.response);
      console.log(e.request);
    }
  };

  const placeMarker = (location) => {

    //let lastAddedPin = allMarkers.at(-1);
    //console.log("zzzzzzzzzz",lastAddedPin);

    let originalMarkers = allMarkers.map(item => {
        //if ( item.id )
            return item;
    });

    originalMarkers.push({
        name: "added marker",
        lat: location.lat(),
        lng: location.lng(),
    });

    setAllMarkers(originalMarkers);

    setPlacedMarker({
      name: "",
      title: "",
      address: "",
      description: "",
      obj_type: "",
      opt_pandus: false,
      opt_toilet: false,
      opt_handling: false,
      opt_elevator: false,
      lat: location.lat(),
      lng: location.lng(),
    });
  };


  useEffect(() => {
    console.log("mapref", mapRef.current.map);
    console.log("Use Effect 251: state.mapCenter");
    console.log(state.mapCenter);
  }, []);

  const handleSelect = (address) => {
    setState({ ...state, address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Success", latLng);

        // update center state
        setState({ ...state, mapCenter: latLng });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleChange = (address) => {
    setState({ ...state, address });
  };

  const getLocationByLatLng = async (latLng) => {
    // setCurrLocationAddress("loading", latLng);
    //
    // const response = await axios.get(
    //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=41.6944227,44.8008687&key=AIzaSyBmHRdO5ZYI8DzGpmjDh4FikJUuf-EWG4Q`
    // );
    //
    // setCurrLocationAddress(response.data.results?.[0]?.formatted_address);
    // return response.data.results?.[0]?.formatted_address;
  };

  // const currentMarkerLocationString =  useMemo(async () => {
  //   return await getLocationByLatLng(state?.activeMarker?.position);
  // }, [state?.activeMarker]);

  const addMarkerHandler = () => {
    props.google.maps.event.addListener(
      mapRef.current.map,
      "click",
      function (event) {
        console.log("Event:");
        console.log(event);
        placeMarker(event.latLng);
        // placeMarker(event.latLng);
      }
    );
    setPlacingMarker(true);
    //setState({ ...state, takingPhoto: true });
  };

  const newPinInputHandler = (e) => {
      const target = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      setPlacedMarker({
        ...placedMarker,
        [name]: value
      });
  }

  const checkCurrentMarkers = async () => {

    // უნა გამოვაჩინოთ ველების შესაყვანი დიალოგი
    setState({
        ...state,
        modalNew: true
    });
    // ფორმით: თაითლი, მისამართი, ახწერა, ჩაკარტოჩკება


    //ველები რო შეივსება მერე დასრულების კნოფგა გამიკეთე

  };

  const sendNewPin = () => {
      console.log('send new pin');
      addMarkerToMap();
  }

  const cameraToggle = () => {
      setState({
          ...state,
          takingPhoto: !state.takingPhoto
      });
  }

  const objtypes = {
      market: 'სავაჭრო ცენტი',
      bank: 'საფინანსო',
      medical: 'სამედიცინო',
      school: 'საგანმანათლებლო',
      play: 'სარეკრეაციო-გასართობი',
      beauty: 'სილამაზე და თვითმოვლა',
      public: 'საჯარო დაწესებულებები'
  }

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: 'calc(100vh - 56px)',
          }}
        >
          {state.takingPhoto ? (
            <Camera
              takePhoto={takePhoto}
              setState={setState}
              cameraToggle={cameraToggle}
              addMarkerToMap={addMarkerToMap}
            />
          ) : (
            <>
              <Map
                ref={mapRef}
                className={"map"}
                google={props.google}
                initialCenter={{
                  lat: state.mapCenter.lat,
                  lng: state.mapCenter.lng,
                }}
                center={{
                  lat: state.mapCenter.lat,
                  lng: state.mapCenter.lng,
                }}
              >
                {allMarkers?.map((marker) => {
                    let pinIcon = MainIcon1;
                    if ( marker?.name == 'added marker' ) {
                        pinIcon = MainIconNew;
                    } else if (marker?.objType == 'school') {
                        pinIcon = MainIcon2;
                    } else if (marker?.objType == 'medical') {
                        pinIcon = MainIcon3;
                    } else if (marker?.objType == 'art') {
                        pinIcon = MainIcon4;
                    } else if (marker?.objType == 'bank') {
                        pinIcon = MainIcon5;
                    } else if (marker?.objType == 'play') {
                        pinIcon = MainIcon6;
                    }
                  return (
                    <Marker
                      key={Math.random()}
                      onClick={() => onMarkerClick(null, marker)}
                      name={marker.name}
                      icon={pinIcon}
                      position={{
                        lat: marker?.lat,
                        lng: marker?.lng,
                      }}
                    />
                  );
                })}

              </Map>


            <MDBModal isOpen={state.modal} toggle={modaltoggle}>
              <MDBModalHeader toggle={modaltoggle}>{state.activeMarker?.name}</MDBModalHeader>
              <MDBModalBody>
                  <MDBCard>
                      {state.activeMarker?.images ? (
                        <MDBCardImage src={state.activeMarker?.images[0]?.image.fullSize} alt="NO IMAGE" top hover
                          overlay="white-slight" />
                      ) : (<></>)}

                      <MDBCardBody>
                        <MDBCardTitle tag="h5">მისამართი: {state.activeMarker?.address}</MDBCardTitle>

                        <MDBCardText>
                            {state.activeMarker?.objType ? (
                                <>ობიექტის ტიპი: {objtypes[state.activeMarker?.objType]} <br /> </>
                            ): (<></>)}

                            { (
                                state.activeMarker?.optElevator ||
                                state.activeMarker?.optHandling ||
                                state.activeMarker?.optPandus ||
                                state.activeMarker?.optToilet
                               ) ? (
                                <>
                                    ადაპტირებულია:
                                        {state.activeMarker?.optElevator ? (<> ლიფტი, </>):(<></>)}
                                        {state.activeMarker?.optHandling ? (<> სახელური, </>):(<></>)}
                                        {state.activeMarker?.optPandus ? (<> პანდუსი, </>):(<></>)}
                                        {state.activeMarker?.optToilet ? (<> საპირფარეშო </>):(<></>)}
                                    <br />
                                </>
                            ): (<></>)}
                        </MDBCardText>
                      </MDBCardBody>
                  </MDBCard>
              </MDBModalBody>
            </MDBModal>


            <MDBModal isOpen={state.modalNew} toggle={modalNewtoggle}>
              <MDBModalHeader toggle={modalNewtoggle}>ახალი ობიექტის დამატება</MDBModalHeader>
              <MDBModalBody>
                  <MDBCard>
                      <MDBCardBody>
                        <MDBCardTitle tag="h5">გთხოვთ, მიუთითოთ ინფორმაცია და გადაუღოთ ფოტო</MDBCardTitle>
                        <MDBCardText>
                            <MDBContainer>
                                <MDBRow>
                                  <MDBCol md="12">
                                    <form>
                                      <div className="">

                                        <MDBInput label="დასახელება" icon="pencil-alt" group type="text" validate error="wrong"
                                          success="right" name="title" value={placedMarker?.title} onChange={ newPinInputHandler} />

                                        <MDBInput label="მისამართი" value={placedMarker?.address}  name="address" icon="pencil-alt" group type="text" validate error="wrong"
                                          success="right"  onChange={ newPinInputHandler} />

                                        <MDBInput type="textarea" rows="2" name="description" label="კომენტარი" icon="pencil-alt"  onChange={ newPinInputHandler } value={placedMarker?.description}  />

                                        <div className="selectz">
                                            <select name="obj_type" className="browser-default custom-select"  onChange={ newPinInputHandler}>
                                              <option disabled selected>დაწესებულების ტიპი</option>

                                              <option value="market">სავაჭრო ცენტი</option>
                                              <option value="bank">საფინანსო</option>
                                              <option value="medical">სამედიცინო</option>
                                              <option value="school">საგანმანათლებლო</option>
                                              <option value="play">სარეკრეაციო-გასართობი</option>
                                              <option value="beauty">სილამაზე და თვითმოვლა</option>
                                              <option value="public">საჯარო დაწესებულებები</option>

                                              <option value="">სხვა</option>
                                            </select>
                                        </div>

                                        <div className="checks">
                                             <MDBInput onChange={ newPinInputHandler} label="აქვს პანდუსი" type="checkbox" name="opt_pandus" id="checkbox1" onChange={ newPinInputHandler}/>
                                             <MDBInput onChange={ newPinInputHandler} label="ადაპტირებულია ლიფტი" type="checkbox" name="opt_elevator" id="checkbox2" />
                                             <MDBInput onChange={ newPinInputHandler} label="ადაპტირებულია საპირფარეშო" type="checkbox" name="opt_toilet" id="checkbox3" />
                                             <MDBInput onChange={ newPinInputHandler} label="ადაპტირებული სახელური" type="checkbox" name="opt_handling" id="checkbox4" />
                                         </div>

                                      </div>
                                      <div className="text-center">
                                        <MDBBtn color="success" onClick={ cameraToggle }>
                                           <MDBIcon icon="camera" className="mr-2" />
                                           გადაუღე ფოტო
                                        </MDBBtn>
                                      </div>
                                    </form>
                                  </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBCardText>
                      </MDBCardBody>
                  </MDBCard>
              </MDBModalBody>
            </MDBModal>


              {state.showingInfoWindow ? (
                <div className={"details-wrapper"}>


                  <button
                    onClick={() =>
                      setState({ ...state, showingInfoWindow: false })
                    }
                  >
                    close
                  </button>

                </div>
              ) : (
                <>


                  {!placingMarker && !placedMarker && (
                      <MDBBtn color="success" id="add_new_plus" onClick={() => addMarkerHandler()}>
                        <MDBIcon icon="plus" />
                      </MDBBtn>
                  )}


                  {placingMarker && !placedMarker && (

                    <MDBNotification
                      id="add_new_plus"
                      show
                      fade
                      icon="map-marker-alt"
                      iconClassName="green-text"
                      title="მონიშნეთ ადგილი"
                      message="ადაპტირებული სივრცის დასამატებლად, მონიშნეთ ადგილი, დასვით პინი რუკაზე და მიჰყევით ინსტრუქციას"
                      text=""
                    />
                  )}


                  {placingMarker && placedMarker && (
                      <MDBBtn color="success" id="msg_droppin_btn" onClick={() => checkCurrentMarkers()}><MDBIcon icon="arrow-circle-right" className="mr-2" /> შეიყვანეთ ინფორმაცია</MDBBtn>
                  )}
                </>
              )}


            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey,
})(MapCountainer);
