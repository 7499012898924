import React, { Component, useEffect, useState } from 'react';

import {
    MDBContainer, MDBRow, MDBCol,
    MDBCard, MDBCardTitle, MDBBtn, MDBCardGroup, MDBCardImage, MDBCardText, MDBCardBody
} from "mdbreact";

import axios from "axios";

//const apiUrl = "http://127.0.0.1:8000";
const apiUrl = "";



const List = (props) => {

    const [allMarkers, setAllMarkers] = useState([]);


    useEffect(async () => {
      const response = await axios.get(`${apiUrl}/api/map/markers/`);
      setAllMarkers(response.data);
    }, []);


    const objtypes = {
        market: 'სავაჭრო ცენტი',
        bank: 'საფინანსო',
        medical: 'სამედიცინო',
        school: 'საგანმანათლებლო',
        play: 'სარეკრეაციო-გასართობი',
        beauty: 'სილამაზე და თვითმოვლა',
        public: 'საჯარო დაწესებულებები'
    }


    return (<>

                    <MDBContainer className="pt-4">
                      <MDBRow>

                          { allMarkers?.map((marker) => {
                            return (
                                <MDBCol md='4'>
                                    <MDBCard className="mb-2">
                                        <MDBCardImage src={marker?.images[0]?.image.fullSize} alt="No Image" top overlay="white-slight" />
                                        <MDBCardBody>
                                          <MDBCardTitle tag="h5">{marker.name}</MDBCardTitle>
                                          <MDBCardText>
                                            მისამართი: {marker.address} <br/>

                                            {marker?.objType ? (
                                                <>ობიექტის ტიპი: {objtypes[marker?.objType]} <br /> </>
                                            ): (<></>)}

                                            { (
                                                marker?.optElevator ||
                                                marker?.optHandling ||
                                                marker?.optPandus ||
                                                marker?.optToilet
                                               ) ? (
                                                <>
                                                    ადაპტირებულია:
                                                        {marker?.optElevator ? (<> ლიფტი, </>):(<></>)}
                                                        {marker?.optHandling ? (<> სახელური, </>):(<></>)}
                                                        {marker?.optPandus ? (<> პანდუსი, </>):(<></>)}
                                                        {marker?.optToilet ? (<> საპირფარეშო </>):(<></>)}
                                                    <br />
                                                </>
                                            ): (<></>)}

                                          </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            );
                          })}


                      </MDBRow>
                    </MDBContainer>


        </>);

}



export default List;
